import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import pathOr from 'ramda/src/pathOr';
import clone from 'ramda/src/clone';
import moment from 'moment';
import {
    stateGo,
    stateReload
} from 'redux-ui-router';
import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';
import {
    CanUserUpdatePinSelector,
    CreateCustomerStateRegionProvinceValueOptions,
    CurrentCustomerAccountingMethodSelector,
    CurrentCustomerBillCycleSelector,
    CurrentCustomerChargeTypeForDetailedTaxationSelector,
    CurrentCustomerExternalAuthorizationsSelector,
    CurrentCustomerHomeCountryNameSelector,
    CurrentCustomerIdSelector,
    CurrentCustomerInvoiceDetailStatus,
    CurrentCustomerNewBillCycleSelector,
    CurrentCustomerAdjustmentDueDaysSelector,
    CurrentCustomerPaymentDueDaysSelector,
    CurrentCustomerSelector,
    CurrentCustomerSubscriberCurrencyNameSelector,
    CurrentCustomerSubscriberPreferredTaxReportingCurrencySelector,
    CurrentCustomerSubtenantSelector,
    CustomerContactPreferencesSelector,
    CustomerIsFetchingDataSelector,
    CustomerPinRegexSelector,
    CustomerPreferredMethodOfContactSelector,
    IsPIIDataAccessEnabledSelector,
    IsCreatingCustomerSelector,
    IsCurrentCustomerHierarchyRootOrNotInHierarchySelector,
    IsSelectedCustomerWithPinSelector,
    SelectedCustomerAdditionalPropertiesSortedByDisplayOrderSelector,
    SelectedCustomerConsentsMandatorySelector,
    SelectedCustomerConsentsMandatoryUnacceptedSelector,
    SelectedCustomerConsentsOptionalSelector,
    SelectedCustomerRequirements,
    ViewLastAttemptErrorSelector
} from '../../../reducers/selectors/customer.selectors';
import {
    ContactMethodOptionsSelector,
    CurrentCustomerProfileMoreMenuItemsSelector,
    InvoiceDisplaySelector,
    HomeCountriesStatesSelector,
    NotificationPreferencesSelector,
    SelectedTaxLevelExemptionsSelector,
    SubscriberIdentificationsSelector,
    TaxAssociationCodesSelector,
    TaxCategoryExemptionsSelector,
    TaxExemptionTypeCodesSelector,
    TaxLevelCodesSelector,
} from '../../createCustomerPopup/customer.form.dropdown.selectors';
import {CustomerInfoSelector} from '../orders/create/offer/add.offer.wizard.selectors';
import {
    clearCreateEditCustomerForm,
    createEditCustomerFormChanged,
    deleteExternalAuthorization,
    resendVerificationEmail,
    retrieveCustomer,
    searchSubtenants,
    setCustomerCategory,
    setIsEditingProfile,
    updateSubscriber,
    updateSubscriberBillCycle,
    updateSubscriberPIN
} from '../../../reducers/actions/customer.actions';
import {
    registerUnsavedChanges,
    unregisterUnsavedChanges
} from 'invision-core/src/components/unsavedChangesPrompt/actions';
import {
    hasAccess,
    hasAdminAccess
} from 'invision-core/src/components/security/permission.service';
import {UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';
import {BILLING_CONFIGURATION_TYPES} from 'invision-core/src/components/metadata/billingConfiguration/billing.configuration.action.types';
import {subscriberOrderTypeEnum} from 'invision-core/src/utilities/subscriber.order.type.enum';
import {BillingConfigurationLoadedSelector} from 'invision-core/src/components/metadata/billingConfiguration/billing.configuration.selectors';
import {MetadataActions} from 'invision-core';
import {
    fetchCodeTypes,
    fetchExternalAuthorizationProviders,
    fetchConsents,
    fetchTaxLevels
} from 'invision-core/src/components/metadata/codes/codes.actions';
import i18n from 'invision-core/src/components/i18n/i18n';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import {
    MetadataCodeLoadedSelector,
    MetadataCodeTypeSelector,
    SubscriberRequirementsForCurrentBusinessUnitOrDefault,
    SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {
    CUSTOMER_CATEGORY
} from '../../createCustomerPopup/create.customer.popup.constants';
import {
    INVOICE_DETAIL_LEVEL,
    NOTIFICATION_TIME_LENGTH
} from '../../../customercare.constants';
import {getNameByCountryCode} from '../customer.helper';
import CustomerCareLocaleKeys from '../../../locales/keys';
import {
    EMAIL_VERIFICATION_STATUS,
    PROFILE_ACTIVE_STATUS,
    PROFILE_ANONYMIZED_STATUS,
    PROFILE_EVENTS,
    PROFILE_OPTION_REACTIVATE_ID,
    PROFILE_OPTION_SCHEDULE_ANONYMIZATION_ID,
    PROFILE_OPTION_ANONYMIZE_IMMEDIATELY_ID,
    PROFILE_REMOVED_STATUS
} from './profile.constants';
import {
    ANONYMIZE_SUBSCRIBER_IMMEDIATELY_ACCESS,
    BILL_CYCLE_CHANGE_ACCESS,
    SUBSCRIBER_ADMIN_ACCESS,
    SUBSCRIBER_TYPE_EDIT_ACCESS
} from '../../../security.attributes';
import {loadSubscriberRequirements} from '../../../reducers/helpers/subscriber.requirements.actions.helper';
import {IsLoadingInventoryItemsSelector} from '../../../reducers/selectors/customer.inventory.selectors';
import {IsFetchingAccountStatusDataSelector} from '../../../reducers/selectors/customer.account.status.selectors';
import {CurrentBusinessUnitHasSubtenants} from '../../../reducers/selectors/subtenant.selectors';
import {SelectedCustomerAddressSelector} from '../../../reducers/selectors/products.order.selectors';
import {
    BillCycleDetailsSelector,
    CandidateBillCyclesSelector,
    IsRetrievingBillCycleDetailsSelector,
    NewBillCycleDetailsSelector
} from '../../../reducers/selectors/customer.billcycle.selectors';
import {
    retrieveBillCycleDetails,
    retrieveCandidateBillCycles,
    retrieveNextBillCycleDetails
} from '../../../reducers/actions/customer.billcycle.actions';
import {getEditCustomerModel} from './profile.helper';
import {
    IsCreditCheckEnabledSelector,
    IsCreditCheckSuccessSelector,
    IsMatchSubscriberCreditCheckDetailsSelector,
    SubscriberCreditCheckDetailsSelector,
    SubscriberCreditClassificationStatusSelector
} from '../../../reducers/selectors/credit.check.selectors';
import {performCreditCheck} from '../../../reducers/actions/credit.check.actions';
import {CREDIT_CHECK_STATUS} from '../../../reducers/constants/credit.check.constants';
import {SubtenantsLoadedSelector} from 'invision-core/src/components/subtenants/subtenants.selectors';
import {FormattedEditCustomerSubscriberIdentitiesSelector} from '../../../reducers/selectors/customer.information.selectors';
import {ACCOUNTING_METHODS} from '../../../components/createCustomer/billingInformationSection/billing.information.section.constants';
import {CUSTOMER_STATE} from '../../shared/constants/customer.constants';
import {REPORTING_LEVELS} from '../accountHierarchy/account.hierarchy.constants';
export class CustomerProfileController {

    constructor($ngRedux, $q, $scope, $stateParams, $timeout, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $q,
            $scope,
            $stateParams,
            $timeout,
            CreditCheckStatus: CREDIT_CHECK_STATUS,
            CustomerCareLocaleKeys: CustomerCareLocaleKeys,
            CustomerCategory: CUSTOMER_CATEGORY,
            CUSTOMER_STATE,
            editCustomerFormErrors: [],
            EMAIL_VERIFICATION_STATUS,
            emailVerificationStatus: null,
            isAccountingMethodEditable: false,
            isBillCycleChangeAllowed : false,
            isDataLoaded: false,
            notificationPreferencesSection: [],
            onCustomerBillingDetailsUpdateModal: this.onCustomerBillingDetailsUpdateModal.bind(this),
            onSubmitCustomerPinModal: this.onSubmitCustomerPinModal.bind(this),
            callRetrieveSubscriber: this.callRetrieveSubscriber.bind(this),
            canUserEdit: this.canUserEdit.bind(this),
            closeEditCustomerModal: this.closeEditCustomerModal.bind(this),
            closeCreditCheckModal: this.closeCreditCheckModal.bind(this),
            isResidentialCustomer: this.isResidentialCustomer.bind(this),
            getAccountingMethod: this.getAccountingMethod.bind(this),
            onCreateCustomerModalError: this.onCreateCustomerModalError.bind(this),
            onSubmitEditCustomerModal: this.onSubmitEditCustomerModal.bind(this),
            saveCreditCheckModal: this.saveCreditCheckModal.bind(this),
            PROFILE_ACTIVE_STATUS,
            REPORTING_LEVELS,
            requirements: {},
            showLoadingIndicator: false,
            showEditCustomerModal: false,
            showCreditCheckModal: false,
            stateOrName: 'index.customercare.customer.profile',
            uiNotificationService
        });
    }

    $onInit() {
        this.$scope.$on(PROFILE_EVENTS.OPEN_EDIT_CUSTOMER_MODAL, () => {
            this.openEditProfileInformationPopup(this.state.currentCustomer);
        });

        const mapStateToTarget = (store) => {
            return {
                adjustmentDueDays: CurrentCustomerAdjustmentDueDaysSelector(store),
                billCycleDetails: BillCycleDetailsSelector(store),
                candidateBillCycles: CandidateBillCyclesSelector(store),
                canUserUpdatePin: CanUserUpdatePinSelector(store),
                contactEvents: MetadataCodeTypeSelector(CODES.ContactEvent, store),
                contactEventsLoaded: MetadataCodeLoadedSelector(CODES.ContactEvent, store),
                contactMapping: MetadataCodeTypeSelector(CODES.ContactMapping, store),
                contactMappingLoaded: MetadataCodeLoadedSelector(CODES.ContactMapping, store),
                contactMethodOptions: ContactMethodOptionsSelector(store),
                contactMethods: MetadataCodeTypeSelector(CODES.ContactMethod, store),
                contactMethodsLoaded: MetadataCodeLoadedSelector(CODES.ContactMethod, store),
                createCustomerDriversLicenseStateOptions: CreateCustomerStateRegionProvinceValueOptions(store),
                currentBusinessUnitHasSubtenants: CurrentBusinessUnitHasSubtenants(store),
                currentAddresses: SelectedCustomerAddressSelector(store),
                currentCustomer: CurrentCustomerSelector(store),
                currentCustomerAdditionalProperties: SelectedCustomerAdditionalPropertiesSortedByDisplayOrderSelector(store),
                currentCustomerBillCycle: CurrentCustomerBillCycleSelector(store),
                currentCustomerChargeTypeForDetailedTaxation: CurrentCustomerChargeTypeForDetailedTaxationSelector(store),
                currentCustomerConsentsMandatory: SelectedCustomerConsentsMandatorySelector(store),
                currentCustomerConsentsMandatoryUnaccepted: SelectedCustomerConsentsMandatoryUnacceptedSelector(store),
                currentCustomerConsentsOptional: SelectedCustomerConsentsOptionalSelector(store),
                currentCustomerContactPreferences: CustomerContactPreferencesSelector(store),
                currentCustomerExternalAuthorizations: CurrentCustomerExternalAuthorizationsSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                currentCustomerNewBillCycle: CurrentCustomerNewBillCycleSelector(store),
                currentCustomerPreferredMethodOfContact: CustomerPreferredMethodOfContactSelector(store),
                currentCustomerSubtenant: CurrentCustomerSubtenantSelector(store),
                currentCustomerAccountingMethod: CurrentCustomerAccountingMethodSelector(store),
                customerInfo: CustomerInfoSelector(store),
                externalAuthorizationProviders: MetadataCodeTypeSelector(CODES.ExternalAuthorizationProvider, store),
                formattedEditCustomerSubscriberIdentities: FormattedEditCustomerSubscriberIdentitiesSelector(store),
                homeCountries: MetadataCodeTypeSelector(CODES.AddressCountry, store),
                homeCountriesLoaded: MetadataCodeLoadedSelector(CODES.AddressCountry, store),
                homeCountriesStates: HomeCountriesStatesSelector(store),
                homeCountriesStatesLoaded: MetadataCodeLoadedSelector(CODES.AddressStateProvinceRegion, store),
                homeCountryName: CurrentCustomerHomeCountryNameSelector(store),
                invoiceDetailStatus: CurrentCustomerInvoiceDetailStatus(store),
                invoiceDisplays: InvoiceDisplaySelector(store),
                isBillCycleLoaded: BillingConfigurationLoadedSelector(BILLING_CONFIGURATION_TYPES.BILL_CYCLES_CODE, store),
                isPIIDataAccessEnabled: IsPIIDataAccessEnabledSelector(store),
                isConsentsLoaded: MetadataCodeLoadedSelector(CODES.Consents, store),
                isCreatingCustomer: IsCreatingCustomerSelector(store),
                isCreditCheckEnabled: IsCreditCheckEnabledSelector(store),
                isCreditCheckSuccess: IsCreditCheckSuccessSelector(store),
                isCurrentCustomerHierarchyRootOrNotInHierarchy: IsCurrentCustomerHierarchyRootOrNotInHierarchySelector(store),
                isDbss: IsDbss(store),
                isExternalAuthorizationLoaded: MetadataCodeLoadedSelector(CODES.ExternalAuthorizationProvider, store),
                isFetchingAccountStatusData: IsFetchingAccountStatusDataSelector(store),
                isFetchingData: CustomerIsFetchingDataSelector(store),
                isInvoiceDetailsLevelsLoaded: MetadataCodeLoadedSelector(CODES.InvoiceDetailLevel, store),
                isInvoiceDisplayLoaded: BillingConfigurationLoadedSelector(BILLING_CONFIGURATION_TYPES.INVOICE_DISPLAY_CODE, store),
                isLoadingInventory: IsLoadingInventoryItemsSelector(store),
                isMatchSubscriberCreditCheckDetails: IsMatchSubscriberCreditCheckDetailsSelector(store),
                isRetrievingBillCycleDetails: IsRetrievingBillCycleDetailsSelector(store),
                isSelectedCustomerWithPin: IsSelectedCustomerWithPinSelector(store),
                isTaxExemptionCodeLoaded: BillingConfigurationLoadedSelector(BILLING_CONFIGURATION_TYPES.TAX_EXEMPTION_CATEGORY_CODE, store),
                isTaxLevelCodesLoaded: MetadataCodeLoadedSelector(CODES.TaxLevel, store),
                isTaxTypeAssociationCodeLoaded: BillingConfigurationLoadedSelector(BILLING_CONFIGURATION_TYPES.TAX_ASSOCICATION_CODE, store),
                isTaxTypeCodesLoaded: BillingConfigurationLoadedSelector(BILLING_CONFIGURATION_TYPES.TAX_EXEMPTION_TYPE_CODE, store),
                languages: MetadataCodeTypeSelector(CODES.Language, store),
                languagesLoaded: MetadataCodeLoadedSelector(CODES.Language, store),
                lastAttemptError: ViewLastAttemptErrorSelector(store),
                moreOptionsMenuItems: CurrentCustomerProfileMoreMenuItemsSelector(store),
                notificationCategoryPreference: NotificationPreferencesSelector(store),
                newBillCycleDetails: NewBillCycleDetailsSelector(store),
                paymentDueDays: CurrentCustomerPaymentDueDaysSelector(store),
                personGenderCodes: MetadataCodeTypeSelector(CODES.PersonGender, store),
                personRaceCodes: MetadataCodeTypeSelector(CODES.PersonRace, store),
                pinRegex: CustomerPinRegexSelector(store),
                selectedCustomerRequirements: SelectedCustomerRequirements(store),
                subscriberCategoryRequirements: SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault(store),
                subscriberCreditCheckDetails: SubscriberCreditCheckDetailsSelector(store) || {},
                subscriberCreditClassificationStatus: SubscriberCreditClassificationStatusSelector(store),
                subscriberCurrencies: MetadataCodeTypeSelector(CODES.Currency, store),
                subscriberCurrenciesLoaded: MetadataCodeLoadedSelector(CODES.Currency, store),
                subscriberCurrencyName: CurrentCustomerSubscriberCurrencyNameSelector(store),
                subscriberPreferredReportingTaxCurrencyName: CurrentCustomerSubscriberPreferredTaxReportingCurrencySelector(store),
                subscriberIdentifications: SubscriberIdentificationsSelector(store),
                subscriberIdentificationsLoaded: MetadataCodeLoadedSelector(CODES.SubscriberIdentifications, store),
                subscriberIncomeLevelTypeCodes: MetadataCodeTypeSelector(CODES.SubscriberIncomeLevelType, store),
                subscriberLeadSourceTypeCodes: MetadataCodeTypeSelector(CODES.SubscriberLeadSourceType, store),
                subscriberRequirements: SubscriberRequirementsForCurrentBusinessUnitOrDefault(store),
                subscriberTypeCodes: MetadataCodeTypeSelector(CODES.SubscriberType, store),
                subTenantsLoaded: SubtenantsLoadedSelector(store),
                taxAssociationCodes: TaxAssociationCodesSelector(store),
                taxCategoryExemptions: TaxCategoryExemptionsSelector(store),
                taxExemptionTypes: TaxExemptionTypeCodesSelector(store),
                taxLevelCodes: TaxLevelCodesSelector(store),
                taxLevelExemptions: SelectedTaxLevelExemptionsSelector(store),
                userSecurityAttributes: UserSecurityAttributesSelector(store)
            };
        };

        const controllerActions = {
            clearCreateEditCustomerForm,
            createEditCustomerFormChanged,
            deleteExternalAuthorization,
            fetchCodeType: fetchCodeTypes,
            fetchExternalAuthorizationProviders: fetchExternalAuthorizationProviders,
            getConsents: fetchConsents,
            getInvoiceDisplay: MetadataActions.billingConfiguration.getInvoiceDisplay,
            getTaxAssociationCodes: MetadataActions.billingConfiguration.getTaxAssociationCodes,
            getTaxExemptionCategories: MetadataActions.billingConfiguration.getTaxExemptionCategories,
            getTaxExemptionTypes: MetadataActions.billingConfiguration.getTaxExemptionTypes,
            getTaxLevelCodes: fetchTaxLevels,
            loadSubscriberRequirements,
            performCreditCheck,
            registerUnsavedChanges: registerUnsavedChanges,
            resendVerificationEmail,
            retrieveBillCycleDetails,
            retrieveCandidateBillCycles,
            retrieveCustomer,
            retrieveNextBillCycleDetails,
            searchSubtenants: searchSubtenants,
            setCustomerCategory,
            setIsEditingProfile,
            stateGo,
            stateReload: stateReload,
            unregisterUnsavedChanges: unregisterUnsavedChanges,
            updateSubscriber,
            updateSubscriberBillCycle,
            updateSubscriberPIN
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.actions.fetchExternalAuthorizationProviders();
        this.actions.loadSubscriberRequirements().then(() => {
            this.requirements = this.state.currentCustomer.Category === CUSTOMER_CATEGORY.RESIDENTIAL ? this.state.subscriberRequirements : this.state.subscriberCategoryRequirements;

            this.isDataLoaded = true;
        });

        if (!this.state.isConsentsLoaded) {
            this.actions.getConsents();
        }

        if (!this.state.notificationCategoryPreference.length) {
            this.actions.fetchCodeType(CODES.NotificationCategories).then(() => {
                this.formatNotificationPreferences();
            });
        } else {
            this.formatNotificationPreferences();
        }

        if (this.state.isDbss) {
            if (!this.state.isInvoiceDetailsLevelsLoaded) {
                this.actions.fetchCodeType(CODES.InvoiceDetailLevel);
            }

            if (!this.state.isTaxTypeCodesLoaded) {
                this.actions.getTaxExemptionTypes();
            }
            if (!this.state.isTaxLevelCodesLoaded) {
                this.actions.getTaxLevelCodes();
            }

            if (!this.state.homeCountriesLoaded) {
                this.actions.fetchCodeType(CODES.AddressCountry);
            }
            if (!this.state.subscriberCurrenciesLoaded) {
                this.actions.fetchCodeType(CODES.Currency);
            }

            this.actions.searchSubtenants().then(() => {})
                .catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);
                });

            if (!this.state.contactMethodsLoaded) {
                this.actions.fetchCodeType(CODES.ContactMethod);
            }

            if (!this.state.contactEventsLoaded) {
                this.actions.fetchCodeType(CODES.ContactEvent);
            }

            if (!this.state.contactMappingLoaded) {
                this.actions.fetchCodeType(CODES.ContactMapping);
            }

            if (!this.state.subscriberIdentificationsLoaded) {
                this.actions.fetchCodeType(CODES.SubscriberIdentifications).then(() => {
                    this.formatSubscriberIdentifications();
                });
            } else {
                this.formatSubscriberIdentifications();
            }

            if (!this.state.isTaxTypeAssociationCodeLoaded) {
                this.actions.getTaxAssociationCodes();
            }

            this.actions.fetchCodeType(CODES.FeatureToggleConfig);
        }

        this.triggerResendEmail = () => {
            this.actions.resendVerificationEmail(this.state.currentCustomer.Id).then(() => {
                this.uiNotificationService.success(i18n.translate(CustomerCareLocaleKeys.PROFILE_PAGE.VERIFICATION_EMAIL_SENT_SUCCESS));
            });
        };

        this.removeAuthErrorPopupConfig = {
            onRegisterApi: (event) => {
                this.removeAuthErrorPopupApi = event.api;
            }
        };

        this.optionalParams = {
            customerId: this.state.currentCustomerId
        };
        this._retrieveCustomer(true).then(() => {
            this.setCustomerTypeChangeReadOnly();
            this.actions.setCustomerCategory(this.state.currentCustomer.Category);
            if (this.state.currentCustomerBillCycle) {
                this.actions.retrieveBillCycleDetails(this.state.currentCustomerBillCycle);
            }
            if (this.state.currentCustomerNewBillCycle) {
                this.actions.retrieveNextBillCycleDetails(this.state.currentCustomerNewBillCycle);
            }
            if (this.state.isDbss) {
                this.actions.retrieveCandidateBillCycles(this.state.currentCustomerId, subscriberOrderTypeEnum.postpaid.value)
                    .then(() => {
                        if (this.state.currentCustomer.HierarchyId) {
                            this.isBillCycleChangeAllowed = !(this.state.currentCustomer.HierarchyParentSubscriberId);
                        } else if (this.state.currentCustomer.HouseholdMemberPrivileges) {
                            this.isBillCycleChangeAllowed = false;
                        } else {
                            this.isBillCycleChangeAllowed = true;
                        }
                    }).catch((error) => {
                        this.uiNotificationService.transientError(error.translatedMessage);
                    });
            }
        }).then(() => {
            if (!this.state.subscriberCreditCheckDetails.isLoaded || !this.state.isMatchSubscriberCreditCheckDetails) {
                this.actions.performCreditCheck(null, this.state.currentCustomerId);
            }
        });
        this.loadBillingConfigurations();
        this.unsavedChangesPromptPopupConfig = {
            onRegisterApi: ({api: api}) => {
                this.unsavedChangesPromptPopupApi = api;
            }
        };

        this.customerPinPopupConfig = {
            onRegisterApi: ({api}) => {
                this.customerPinPopupApi = api;
            }
        };

        this.creditCheckPopupConfig = {
            onRegisterApi: ({api}) => {
                this.creditCheckPopupApi = api;
            }
        };

        this.mandatoryConsentsPopupConfig = {
            onRegisterApi: ({api: api}) => {
                this.mandatoryConsentsPopupApi = api;
            }
        };

        this.customerBillingCycleUpdateConfig = {
            onRegisterApi: ({api}) => {
                this.customerBillingCycleUpdatePopupApi = api;
            }
        };

        if (!this.state.languagesLoaded) {
            this.actions.fetchCodeType(CODES.Language);
        }

        this.$timeout(() => {
            if (this.$stateParams.openEditCustomerModal) {
                this.$stateParams.openEditCustomerModal = false;
                this.openEditProfileInformationPopup(this.state.currentCustomer);
            }
            if (this.state.currentCustomer.EmailVerificationExpiration) {
                this.emailVerificationStatus = moment(this.state.currentCustomer.EmailVerificationExpiration).isBefore(moment())
                    ? EMAIL_VERIFICATION_STATUS.SUSPENDED
                    : EMAIL_VERIFICATION_STATUS.PENDING_APPROVAL;
                this.tooltipTemplate = require('./status.tooltip.template.html');
            }
        });
    }

    $onDestroy() {
        this.editProfileInformationPopupApi.close();
        this.disconnectRedux();
    }

    onChangedCreateCustomerForm(customerModel) {
        this.actions.createEditCustomerFormChanged(customerModel);
    }

    openEditProfileInformationPopup(profileInformation) {
        this.showEditCustomerModal = true;
    }

    closeEditCustomerModal() {
        this.showEditCustomerModal = false;
    }

    isResidentialCustomer() {
        return this.state.customerInfo.Category === CustomerCategory.RESIDENTIAL;
    }

    getAccountingMethod() {
        return ACCOUNTING_METHODS?.[this.state.currentCustomerAccountingMethod]?.id;
    };

    onSubmitEditCustomerModal() {
        this.showEditCustomerModal = false;
        this.uiNotificationService.success(i18n.translate(CustomerCareLocaleKeys.PROFILE_PAGE.UPDATE_SUCCESS_MESSAGE), null, {
            timeOut: NOTIFICATION_TIME_LENGTH
        });
        this.$timeout(() => {
            this.actions.stateReload().then(() => {
                this.showLoadingIndicator = false;
            });
        });
    }

    onCreateCustomerModalError = (error) => {
        this.uiNotificationService.transientError(error);
        this.showEditCustomerModal = false;
    };

    openCustomerPinPopup() {
        this.showCustomerPinPopup = true;
        this.$timeout(() => {
            this.customerPinPopupApi.open();
        });
    }

    closeCustomerPinPopup() {
        this.showCustomerPinPopup = false;
        this.customerPinPopupApi.close();
    }

    openCustomerBillingCycleUpdatePopup() {
        this.showCustomerBillingCycleUpdatePopup = true;
        this.isAccountingMethodEditable = (this.state.currentCustomer ? !this.state.currentCustomer.HierarchyParentSubscriberId : true) &&
            (this.CUSTOMER_STATE.PROSPECT_LITE === convertStringToNumber(this.state.currentCustomer.State) ||
                this.CUSTOMER_STATE.PROSPECT === convertStringToNumber(this.state.currentCustomer.State));
        this.$timeout(() => {
            this.customerBillingCycleUpdatePopupApi.open();
        });
    }

    closeCustomerBillingCycleUpdatePopup() {
        this.showCustomerBillingCycleUpdatePopup = false;
        this.customerBillingCycleUpdatePopupApi.close();
    }

    handleResultRemoveAuthErrorPopup() {
        this.removeAuthErrorPopupApi.close();
    }

    handleCloseRemoveAuthErrorPopup() {
        this.removeAuthErrorPopupApi.close();
    }

    loadBillingConfigurations() {
        if (this.state.isDbss) {
            if (!this.state.isInvoiceDisplayLoaded) {
                this.actions.getInvoiceDisplay();
            }
            if (!this.state.isTaxExemptionCodeLoaded) {
                this.actions.getTaxExemptionCategories();
            }
        }
    }

    canUserEdit() {
        return hasAdminAccess(this.state.userSecurityAttributes, SUBSCRIBER_ADMIN_ACCESS) && this.state.currentCustomer.State !== PROFILE_ANONYMIZED_STATUS && this.state.isPIIDataAccessEnabled;
    }

    canUserRemove() {
        return hasAdminAccess(this.state.userSecurityAttributes, SUBSCRIBER_ADMIN_ACCESS) && !this.state.currentCustomer.ConvergentBillerId;
    }

    canUserAnonymizeImmediately() {
        return hasAccess(this.state.userSecurityAttributes, ANONYMIZE_SUBSCRIBER_IMMEDIATELY_ACCESS);
    }

    isBillingDetailsEditable() {
        return hasAccess(this.state.userSecurityAttributes, BILL_CYCLE_CHANGE_ACCESS) &&
            !this.state.currentCustomer?.SubscriberTypeDetails?.BillCycleChangeInProgress;
    }

    get isCreditClassificationExpired() {
        return (this.CreditCheckStatus.EXPIRED === this.state.subscriberCreditClassificationStatus);
    }

    getBooleanLocaleKey(value) {
        return value ? CustomerCareLocaleKeys.YES : CustomerCareLocaleKeys.NO;
    }

    onDeleteExternalAuthorization(externalAuthId) {
        this.actions.deleteExternalAuthorization(this.state.currentCustomer, externalAuthId).then(() => {
            this.uiNotificationService.success(i18n.translate(CustomerCareLocaleKeys.PROFILE_PAGE.DELETE_AUTH_SUCCESS_MESSAGE), null, {
                timeOut: NOTIFICATION_TIME_LENGTH
            });
        }).catch(() => {
            this.removeAuthErrorPopupApi.open();
        });
    }

    updateCustomerStatus(status) {
        this.handleCloseScheduleAnonymizationPopup();
        const customer = this.state.currentCustomer.asMutable({
            deep: true
        });
        customer.Status = status;
        this.actions.updateSubscriber(customer).then(() => {
            if (status === PROFILE_REMOVED_STATUS) {
                this.uiNotificationService.success(i18n.translate(CustomerCareLocaleKeys.PROFILE_PAGE.REMOVE_SUCCESS_MESSAGE), null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });
            }

            if (status === PROFILE_ACTIVE_STATUS) {
                this.uiNotificationService.success(i18n.translate(CustomerCareLocaleKeys.PROFILE_PAGE.REACTIVATE_SUCCESS_MESSAGE), null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });
            }

            this._retrieveCustomer();
        }).catch(() => {
            this.uiNotificationService.error(
                i18n.translate(CustomerCareLocaleKeys.PROFILE_PAGE.REMOVE_FAILURE_MESSAGE),
                this.state.lastAttemptError.translatedMessage,
                {
                    timeOut: NOTIFICATION_TIME_LENGTH
                }
            );
        });
    }

    callRetrieveSubscriber() {
        this.actions.retrieveCustomer(this.state.currentCustomerId);
        this.actions.stateReload();
    }

    onSubmitCustomerPinModal(subscriberPIN) {
        this.actions.updateSubscriberPIN(subscriberPIN, this.state.currentCustomerId).then(() => {
            this.actions.retrieveCustomer(this.state.currentCustomerId);
            this.uiNotificationService.success(i18n.translate(this.state.isSelectedCustomerWithPin
                ? CustomerCareLocaleKeys.PROFILE_PAGE.CUSTOMER_PIN_UPDATED : CustomerCareLocaleKeys.PROFILE_PAGE.CUSTOMER_PIN_CREATED), null, {
                timeOut: NOTIFICATION_TIME_LENGTH
            });
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });
        this.closeCustomerPinPopup();
    }

    updateSubscriber(selectedBillCycleId, paymentDueDays, adjustmentDueDays, updatedAccountingMethod) {
        const customer = clone(this.state.currentCustomer);
        customer.SubscriberTypeDetails.BillCycle = selectedBillCycleId;
        customer.SubscriberTypeDetails.PaymentDueDays = paymentDueDays;
        customer.SubscriberTypeDetails.AdjustmentDueDays = adjustmentDueDays;
        customer.SubscriberTypeDetails.AccountingMethod = updatedAccountingMethod;
        return this.actions.updateSubscriber(customer).then(() => {
            this.actions.retrieveCustomer(this.state.currentCustomerId).then(() => {
                this.retrieveCandidateBillCyclesForCustomer().then(() => {
                    this.uiNotificationService.success(i18n.translate(CustomerCareLocaleKeys.PROFILE_PAGE.CUSTOMER_BILLING_DETAILS_UPDATED), null, {
                        timeOut: NOTIFICATION_TIME_LENGTH
                    });
                });
            });
            this.closeCustomerBillingCycleUpdatePopup();
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });
    }

    onCustomerBillingDetailsUpdateModal(selectedBillCycle, paymentDueDays, adjustmentDueDays, updatedAccountingMethod) {
        const updateSubscriber = ((paymentDueDays !== null && paymentDueDays !== this.state.currentCustomer.SubscriberTypeDetails.PaymentDueDays) ||
            (adjustmentDueDays !== null && adjustmentDueDays !== this.state.currentCustomer.SubscriberTypeDetails.AdjustmentDueDays) ||
            (updatedAccountingMethod !== null && updatedAccountingMethod !== this.state.currentCustomer.SubscriberTypeDetails.AccountingMethod));

        if (selectedBillCycle?.id && this.state.currentCustomerBillCycle !== selectedBillCycle.id) {
            this.actions.updateSubscriberBillCycle(selectedBillCycle.id, this.state.currentCustomerId).then(() => {
                if (updateSubscriber) {
                    return this.updateSubscriber(selectedBillCycle.id, paymentDueDays, adjustmentDueDays, updatedAccountingMethod);
                } else {
                    return this.actions.retrieveCustomer(this.state.currentCustomerId).then(() => {
                        return this.actions.retrieveBillCycleDetails(this.state.currentCustomerBillCycle).then(() => {
                            this.uiNotificationService.success(i18n.translate(CustomerCareLocaleKeys.PROFILE_PAGE.CUSTOMER_BILL_CYCLE_UPDATED), null, {
                                timeOut: NOTIFICATION_TIME_LENGTH
                            });
                            this.closeCustomerBillingCycleUpdatePopup();
                        });
                    });
                }
            }).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
                this.closeCustomerBillingCycleUpdatePopup();
            });

        } else if (updateSubscriber) {
            this.updateSubscriber(this.state.currentCustomerBillCycle, paymentDueDays, adjustmentDueDays, updatedAccountingMethod);
        }
    }

    retrieveCandidateBillCyclesForCustomer() {
        if (this.state.currentCustomerBillCycle) {
            this.actions.retrieveBillCycleDetails(this.state.currentCustomerBillCycle);
        }
        return this.actions.retrieveCandidateBillCycles(this.state.currentCustomerId, subscriberOrderTypeEnum.postpaid.value)
            .then(() => {
                if (this.state.currentCustomer.HierarchyId) {
                    this.isBillCycleChangeAllowed = !(this.state.currentCustomer && this.state.currentCustomer.HierarchyParentSubscriberId);
                } else if (this.state.currentCustomer.HouseholdMemberPrivileges) {
                    this.isBillCycleChangeAllowed = false;
                } else {
                    this.isBillCycleChangeAllowed = true;
                }
            });
    }

    isStacked() {
        return pathOr(0, ['currentCustomer', 'AdditionalProperties', 'length'], this.state) <= 3;
    }

    resetForm() {
        this.editCustomerFormErrors = [];
        this.showEditProfileInformationPopup = false;
        this.editProfileInformationForm.$setPristine();
        this.editProfileInformationForm.$setUntouched();
    }

    handleResultUnsavedChangesPromptPopup(confirmed) {
        confirmed && this.unsavedChangesPromptConfirmCallback();
        this.unsavedChangesPromptConfirmCallback = null;
        this.unsavedChangesPromptPopupApi.close();
    }

    handleCloseUnsavedChangesPromptPopup() {
        this.unsavedChangesPromptPopupApi.close();
    }

    getBillCycleBillDate() {
        return this.state.billCycleDetails.billDate ?
            i18n.translate(CustomerCareLocaleKeys.PROFILE_PAGE.NEXT_BILL_DATE, {
                billDate: this.state.billCycleDetails.billDate
            }) : i18n.translate(CustomerCareLocaleKeys.PROFILE_PAGE.DATE_UNAVAILABLE);
    }

    getInvoiceDisplay() {
        const selectedInvoiceDisplay = this.state.invoiceDisplays.find((invoiceDisplay) => {
            return invoiceDisplay.Value === pathOr('Default', ['SubscriberTypeDetails', 'InvoiceDisplay'], this.state.currentCustomer);
        });
        return pathOr('', ['Name'], selectedInvoiceDisplay);
    }

    getInvoiceDetailLevel() {
        switch (this.state.currentCustomer.InvoiceConfiguration.InvoiceDetailLevel) {
            case INVOICE_DETAIL_LEVEL.DETAIL:
                return i18n.translate(CustomerCareLocaleKeys.INVOICE_DETAIL_LEVEL_OPTIONS.DETAIL);
            case INVOICE_DETAIL_LEVEL.SUMMARY:
                return i18n.translate(CustomerCareLocaleKeys.INVOICE_DETAIL_LEVEL_OPTIONS.SUMMARY);
            default:
                return '';
        }
    }

    getTaxCategoryExemptionFromName(taxExemption) {
        const taxCategoryExemption = this.state.taxCategoryExemptions.find((taxCategoryExemption) => {
            return taxCategoryExemption.id === taxExemption;
        });
        return pathOr('', ['text'], taxCategoryExemption);
    }

    getTaxExemptionTypeText(taxExemption) {
        const code = this.state.taxLevelCodes.find((code) => {
            return code.Id === `${taxExemption.TaxLevelCode}`;
        });
        const type = this.state.taxExemptionTypes.find((type) => {
            return type.Id === `${taxExemption.TaxType}`;
        });
        const taxAssociationCode = this.state.taxAssociationCodes.find((type) => {
            return type.Id === `${taxExemption.TaxAssociationCode}`;
        });
        const state = this.state.homeCountriesStates.find((type) => {
            return type.Id === `${taxExemption.State}`;
        });
        const OverridePercentage = taxExemption.OverridePercentage !== null ? `/ ${taxExemption.OverridePercentage} %` : '';
        return `${type ? type.Value : ''} / ${code ? `${code.Value }` : ''} / ${taxAssociationCode ? `${taxAssociationCode.Value}` : 'All'} / ${state ? ` ${state.Value}` : 'All'}  ${OverridePercentage} `;
    }

    getFriendlyName(countryCode) {
        return getNameByCountryCode(this.state.languages, countryCode);
    }

    _retrieveCustomer(init) {
        let getCustomerPromise;

        //Retrieving the customer will clear several properties used on the customer dashboard
        if (init && this.state.currentCustomer) {
            getCustomerPromise = Promise.resolve(this.state.currentCustomer);
        } else {
            getCustomerPromise = this.actions.retrieveCustomer(this.state.currentCustomerId);
        }

        return getCustomerPromise;
    }

    isLoading() {
        return this.state.isFetchingData ||
            this.state.isLoadingInventory ||
            this.state.isFetchingAccountStatusData ||
            !this.state.isExternalAuthorizationLoaded ||
            this.showLoadingIndicator ||
            this.state.isRetrievingBillCycleDetails;
    }

    viewMandatoryConsents() {
        this.mandatoryConsentsPopupApi.open();
    }

    openCreditCheckModal() {
        this.showCreditCheckModal = true;
    }

    closeCreditCheckModal() {
        this.showCreditCheckModal = false;
    }

    closeMandatoryConsentsPopup() {
        this.mandatoryConsentsPopupApi.close();
    }

    saveCreditCheckModal() {
    }

    setCustomerTypeChangeReadOnly() {
        const alreadyHasCreditCheckClassification = !!this.state.currentCustomer.CreditCheckClassification;
        const hasEditTypeAccess = hasAccess(this.state.userSecurityAttributes, SUBSCRIBER_TYPE_EDIT_ACCESS);
        this.isCustomerTypeChangeReadOnly = alreadyHasCreditCheckClassification && !hasEditTypeAccess && !this.state.isCreatingCustomer;
    }

    onCreditCheckModalSuccess() {
        this._retrieveCustomer(false).then(() => {
            this.setCustomerTypeChangeReadOnly();
        });
    }

    formatSubscriberIdentifications() {
        this.subscriberIdentifications = this.state.currentCustomer.SubscriberIdentifications ?
            this.state.currentCustomer.SubscriberIdentifications.map((customerIdentification) => {
                const identification = this.state.subscriberIdentifications.find((subscriberIdentification) => {
                    return subscriberIdentification.Value === customerIdentification.SubscriberIdentificationTypeCode;
                }) || null;
                return {
                    Name: identification.Name,
                    SubscriberIdentificationValue: customerIdentification.SubscriberIdentificationValue,
                };
            }) : [];
    }

    formatNotificationPreferences() {
        if (this.state.notificationCategoryPreference.length) {
            this.notificationPreferencesSection = this.state.notificationCategoryPreference.map((notificationCategory) => {
                const optOutOptions = notificationCategory.optOutOptions.map((option) => {
                    return option.label;
                }).join(', ');

                return {
                    customerNotificationCategoryId: notificationCategory.customerNotificationCategoryId,
                    customerNotificationCategoryName: notificationCategory.customerNotificationCategoryName,
                    customerNotificationCategoryOptOutOptions: optOutOptions
                };
            });
        }
    }
}

export default {
    controller: CustomerProfileController,
    controllerAs: 'CustomerProfileController',
    template: require('./profile.html')
};
